<template>
  <div class="error">
    <img
      class="error__ilustration"
      :src="image"
      :alt="`error ${status}`"
    >
    <div class="error__details">
      <h1 class="details__title">
        {{ $t(`pages.error.${status}.title`) }}
      </h1>
      <p class="details__error-type">
        {{ $t(`pages.error.${status}.type`) }}
      </p>
      <h4 class="details__comment">
        {{ $t(`pages.error.${status}.comment`) }}
      </h4>
      <s-button
        size="large"
        icon-left="arrow-left"
        @click="goToPage()"
      >
        {{ $t(`pages.error.${status}.backButton`) }}
      </s-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import urls from '@/utils/urls'

export default {
  computed: {
    ...mapGetters([
      'user',
    ]),
    status() {
      return this.$route.params.status || 404
    },
    image() {
      const images = require.context('../assets/', false, /\.svg$/)

      return images(`./${this.status}.svg`)
    },
  },
  created() {
    this.setLecture(null)
  },
  methods: {
    ...mapActions([
      'setLecture',
    ]),
    goToPage() {
      const { isTeacher, isSchool, isManager } = this.user

      if ([ 404, 500, 502, 503 ].includes(parseInt(this.status, 10))) {
        if (isTeacher || isSchool || isManager) {
          this.$router.push({ name: 'teacher-sas-activities' })
        } else {
          this.$router.push({ name: 'home' })
        }
      } else {
        window.location.href = urls.PORTAL_URL
      }
    },
  },
}
</script>

<style lang="sass">
.error
  display: flex
  flex-direction: row-reverse
  padding: 144px 72px
  justify-content: space-between

  +mq_l
    flex-direction: row-reverse
    padding: 32px 40px

  +mq_s
    display: block

  &__details
    +mq_s
      text-align: center

    .details__title
      color: $color_main
      max-width: 648px
      padding-bottom: 16px

      +mq_l
        font-size: 20px

    .details__error-type
      color: $color-ink-light
      padding-bottom: 32px

      +mq_l
        font-size: 10px
        padding-bottom: 16px

    .details__comment
      color: $color-ink-light
      max-width: 656px
      padding-bottom: 48px

      +mq_l
        font-size: 14px
        font-weight: 400
        padding-bottom: 32px

  &__ilustration
    +mq_l
      align-self: start
      width: 50%

    +mq_s
      width: 100%
      padding-bottom: 16px
</style>
